<template>
  <div>
    <AppLayoutHeader :hide-hamburger-button="true" />
    <div class="form-container">
      <Stage :stage="4" />
      <div class="pa-3">
        <h2 class="--prm --w-bold text-center">{{ $t("text.stage.complete") }}</h2>

        <p class="text-title--szm --w-medium --dark my-6">
          {{ $t("text.register.registerComplete") }}
        </p>

        <v-btn
          elevation="0"
          to="/home"
          class="btn-icon bg-white --prm mt-4 mb-5 text-heading"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t("buttons.home") }}</span>
          <v-icon color="var(--color__primary)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/StageRegister'
import AppLayoutHeader from '@/layout/AppLayout/components/AppLayoutHeader'

export default {
  components: {
    AppLayoutHeader,
    Stage
  }
}
</script>
